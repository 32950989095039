import React, { useEffect, useState } from "react";

export function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(false);
  useEffect(() => {
    if (window && window.innerWidth < 640) {
      setIsMobile(true);
    }
  }, []);
  return isMobile;
}

export function useWindowSize() {
  const [windowWidth, setWindowWidth] = useState(undefined);
  const [windowHeight, setWindowHeight] = useState(undefined);

  useEffect(() => {
    function setWindowDimensions() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener("resize", setWindowDimensions);
    setWindowDimensions();

    return () => window.removeEventListener("resize", setWindowDimensions);
  }, []);

  return { windowWidth, windowHeight };
}
